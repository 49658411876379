import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

const Hero = () => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        site {
          siteMetadata {
            author
            title
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <div className="flex flex-wrap max-w-2xl mx-auto w-full">
          <div className="px-8 w-full">
            <h2>About</h2>
            <hr />
          </div>
        </div>
        <div className="flex flex-wrap max-w-2xl mb-16 mx-auto px-4 w-full">
          <div className="p-4 w-full md:w-1/2">
            <h3>Firm Profile</h3>
            <p>
              <em>{data.site.siteMetadata.title}</em> became an independent firm
              in 2007, and since that time has experienced significant growth
              due to the wonderful relationship we form with all our clients and
              our personal service that makes us a part of our clients'
              financial life.
            </p>
            <p>
              The most rewarding part of our business is seeing our clients
              succeed and watching the growth in their financial life along with
              their personal life. From a small firm of two to a firm now
              comprising 6 hardworking and dedicated staff,{' '}
              {data.site.siteMetadata.title} is a proud, honest and dedicated
              firm that doesn’t always give the answer the client wants to hear
              but give the answer the client needs to hear.
            </p>
          </div>
          <div className="p-4 w-full md:w-1/2">
            <h3>{data.site.siteMetadata.author}</h3>
            <p>
              Graduating in '99 from Southern Cross University, and completing
              the CPA program in 2005, Michael worked with 3 firms in the local
              Port Macquarie area, gaining experience in all facets of
              accounting, on establishing {data.site.siteMetadata.title},
              Michael grew the firm to also service larger firms and
              international businesses.
            </p>
            <p>
              Through persistence and integrity in actions, the firm takes great
              pride in the care and attention given to clients and the efforts
              of all the staff.
            </p>
          </div>
        </div>
        <br />
      </div>
    )}
  />
);

export default Hero;
