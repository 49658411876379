import React from 'react';

const Affiliated = () => (
  <div>
    <div className="flex flex-wrap max-w-2xl mx-auto w-full">
      <div className="px-8 w-full">
        <h2>Affiliated Companies</h2>
        <hr />
      </div>
    </div>
    <div className="flex flex-wrap max-w-2xl mb-16 mx-auto px-4 w-full">
      <div className="p-4 w-full md:w-1/2">
        <h3>
          <a href="https://www.ccaba.com.au/">
            Coastal Commercial &amp; Business Agents
          </a>
        </h3>
        {/* <p>
          Confidentiality guaranteed, Coastal Commercial &amp; Business and
          Agents are qualified Professional Business Brokers with a wealth of
          business experience.
        </p> */}
      </div>
      {/* <div className="p-4 w-full md:w-1/2">
        <h3>
          <a href="http://www.lifespanfp.com.au/adviser/coastalfis/">
            Coastal Financial &amp; Insurance Services
          </a>
        </h3>
        <p>
          Coastal Financial &amp; Insurance Services is a locally owned
          financial planning practice established to provide clients with
          comprehensive financial planning services.
        </p>
      </div> */}
    </div>
    <br />
  </div>
);

export default Affiliated;
