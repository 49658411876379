import PropTypes from 'prop-types';
import React from 'react';

const Banner = ({ children }) => (
  <div className="banner bg-green-600 text-green-050 flex items-center justify-center mb-24 mx-auto overflow-x-auto overflow-y-hidden pin-l pb-32 pt-16 px-4 md:px-8 w-full">
    {children}
  </div>
);

Banner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Banner;
