import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';

import Image from './Image';

const Hero = () => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        site {
          siteMetadata {
            author
            title
          }
        }
      }
    `}
    render={(data) => (
      <div className="hero flex items-center max-w-3xl mx-auto overflow-hidden relative w-full">
        <div className="office-container">
          <Image />
        </div>
        <div className="max-w-sm px-4 py-16 relative rounded w-full">
          <Link to="/">
            <h1 className="text-5xl">{data.site.siteMetadata.title}</h1>
          </Link>
          <blockquote className="border-green-600">
            <p className="text-grey-darkest text-xl">
              “The most rewarding part of our business is seeing our clients
              succeed, and watching the growth in their financial life along
              with their personal life”
            </p>
            <p className="text-grey-darkest">
              —{data.site.siteMetadata.author}
            </p>
          </blockquote>
        </div>
      </div>
    )}
  />
);

export default Hero;
