import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const ServiceSection = () => (
  <div className="max-w-2xl mx-auto px-4 py-8 w-full">
    <h2 className="font-sans text-3xl md:text-4xl">Services</h2>
    <hr />
    <div className="flex flex-wrap w-full">
      <div className="flex flex-wrap md:mb-6 w-full">
        {/* Accounting */}
        <Services title="Accounting" link="accounting">
          <Service>Benchmarking</Service>
          <Service>Bookkeeping/Accounting</Service>
          <Service>Budgeting</Service>
          <Service>Cash Flow Forecasting</Service>
          <Service>Management Consulting</Service>
          <Service>Office Duties</Service>
          <Service>Payroll Services</Service>
          <Service>Software Solutions</Service>
          <Service>Strategic Planning</Service>
        </Services>

        {/* Small Business */}
        <Services title="Small Business" link="small-business">
          <Service>Business Start-up</Service>
          <Service>Succession Planning</Service>
          <Service>Company Secretary</Service>
        </Services>

        {/* Taxation */}
        <Services title="Taxation Services" link="taxation">
          <Service>Taxation</Service>
          <Service>GST</Service>
          <Service>Capital Gains Tax</Service>
        </Services>

        {/* Financial Services */}
        <Services title="Financial Planning" link="financial-planning">
          <Service>SMSFs</Service>
          <Service>Superannuation</Service>
          <Service>Trusts</Service>
          <Service>Rental Property</Service>
        </Services>
      </div>
    </div>
  </div>
);

const Services = ({ link, title, children }) => (
  <div className="mb-8 px-4 w-full sm:w-1/2 lg:w-1/4">
    <Link to={`/${link}`}>
      <h3 className="text-2xl">{title}</h3>
    </Link>
    <ul className="list-reset">{children}</ul>
  </div>
);

Services.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

const Service = ({ children }) => (
  <li className="flex items-center mb-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="flex-no-shrink h-6 mr-2 w-6"
    >
      <circle cx={12} cy={12} r={10} className="primary" />
      <path
        className="secondary"
        d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
      />
    </svg>
    <span>{children}</span>
  </li>
);

Service.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ServiceSection;
