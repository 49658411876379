import PropTypes from 'prop-types';
import React from 'react';

import Dropbox from '../../images/mjs-dropbox.svg';
import FairWork from '../../images/mjs-fair-work.svg';
import GoogleDrive from '../../images/mjs-google-drive.svg';
import myGov from '../../images/mjs-my-gov.svg';
import MYOB from '../../images/mjs-myob.svg';
import Quickbooks from '../../images/mjs-quickbooks.svg';
import Xero from '../../images/mjs-xero.svg';

const HomeLogins = () => (
  <>
    <div className="flex flex-wrap max-w-2xl mx-auto w-full">
      <div className="px-8 w-full">
        <h2 className="text-center">External Logins</h2>
        <hr />
      </div>
    </div>
    <div className="max-w-2xl mx-auto w-full">
      <div className="flex flex-wrap items-center justify-around max-w-sm md:max-w-lg mb-16 mx-auto px-8 py-4 w-full">
        <Login image={Quickbooks} link="https://qbo.intuit.com/login">
          Quickbooks
        </Login>
        <Login image={Xero} link="https://login.xero.com/">
          Xero
        </Login>
        <Login image={MYOB} link="https://login.myob.com/Account/Login">
          MYOB
        </Login>
        <Login image={myGov} link="https://my.gov.au/LoginServices/main/login">
          myGov
        </Login>
        <Login
          image={FairWork}
          link="https://www.fairwork.gov.au/my-account/fwosignin.aspx"
        >
          Fair&nbsp;Work
        </Login>
        <Login image={Dropbox} link="http://dropbox.com/login">
          Dropbox
        </Login>
        <Login
          image={GoogleDrive}
          link="https://drive.google.com/drive/u/0/my-drive"
        >
          Google&nbsp;Drive
        </Login>
      </div>
    </div>
    <br />
  </>
);

const Login = ({ link, image, children }) => (
  <a
    className="flex flex-col items-center justify-center mb-6 w-1/3 md:w-1/7"
    href={link}
    rel="noopener noreferrer"
    target="_blank"
  >
    <img className="flex h-16 w-16" src={image} alt="" />
    <p className="text-lg text-center">{children}</p>
  </a>
);

Login.propTypes = {
  link: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default HomeLogins;
