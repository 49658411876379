import React from 'react';

import Banner from './index';

const BussinessPlanning = () => (
  <Banner>
    <div className="max-w-md text-xl w-full">
      <blockquote className="border-l-0 font-serif p-0 text-white text-4xl">
        <p className="mb-2">
          “A business that fails to plan,
          <br />
          is planning to fail”
        </p>
        <hr className="bg-green-100 mb-2" />
      </blockquote>
      <div>
        <p>Business plans are a tool used for decision making.</p>
        <p>
          They contain financial strategies and analysis, marketing strategies,
          cash flows, legislative requirements of the business, and (most
          importantly) a map of your future.
        </p>
      </div>
    </div>
  </Banner>
);

export default BussinessPlanning;
