import React from 'react';

import About from '../components/About';
import Affiliated from '../components/Affiliated';
import BusinessPlanning from '../components/Banner/BusinessPlanning';
import Contact from '../components/Contact';
import Hero from '../components/Hero';
import HomeLogins from '../components/HomeLogins';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Services from '../components/Services';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Port Macquarie Business and Personal Accountant"
      keywords={[
        `accountant port macquarie nsw`,
        `tax accountant port macquarie`,
        `accountant jobs port macquarie`,
        `business accountant port macquarie`,
        `sprague accountants port macquarie`,
        `accountant in port macquarie`,
        `michael sprague accountant port macquarie`,
      ]}
    />
    <div className="bg-white">
      <Hero />
      <Contact />
      <HomeLogins />
      <Services />
      <BusinessPlanning />
      <Affiliated />
      <About />
    </div>
  </Layout>
);

export default IndexPage;
